





























































import { mapState, mapActions } from 'vuex'
import api from '@services/api'
import { reportService } from '@services/reportService'
import ModalDrawer from '@components/ModalDrawer.vue'
import AstToolbar from '@components/Toolbar.vue'
import ToolbarButton from '@components/ToolbarButton.vue'
import { eventBus } from '@services/eventBus'
import { Dbounce } from '@utils/dbounce'
import { showAlert } from '../../dialogs/MessageDlg.vue'
import { ReportDetail } from '@reporting/classes'
import { openReportPdf } from '@dialogs/ReportPdf.vue'
import SaveReport from '@reporting/components/SaveReport.vue'
import { showConfirm } from '@dialogs/ConfirmDlg.vue'
import { highlightKeywords } from '@services/hilitor'
import { openEmailStudyDlg } from '@/dialogs/emailStudyDlg.vue'
import { EmailItem } from '@/components/EmailStudyForm.vue'

const { REPORT_COMPLETED, REFRESH_REPORT, IMAGE_CHANGE, REPORT_IMAGE_COMMENT } = eventBus.type
const BackRouteName = 'studies'
let nextReport: ReportDetail = null

export default {
	name: 'StandardReport',
	components: {
		AstToolbar,
		ToolbarButton,
		ModalDrawer,
		SaveReport,
	},
	filters: {
		url(value) {
			if (value.substring(0, 4).toLowerCase() !== 'http') {
				value = 'http://' + value
			}
			return value
		},
	},
	props: {
		id: {
			type: String,
			required: true,
		},
		keywords: {
			type: Array,
			required: false,
		},
		viewOnOpen: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			report: null,
			isActionsOpen: false,
			isSaving: false,
			isPageLoading: false,
		}
	},
	computed: {
		...mapState({
			claims: (state: any) => state.auth.claims,
			permissions: (state: any) => state.static.permissions,
		}),
		canDelete() {
			return (this.permissions.deleteStudy || this.isOwner) && !this.report.isComplete
		},
		isOwner() {
			return this.claims.userId === this.report.ownerId
		},
		isReadOnly() {
			return this.report && this.report.isComplete
		},
		statusClass() {
			if (this.report.isComplete || this.report.isSalesEntry) return 'is-success'
			if (this.report.isLocked) return 'is-danger'
			if (this.report.groupConsultantId) return 'is-info'
			return 'is-warning'
		},
		statusIcon() {
			if (this.report.isComplete || this.report.isSalesEntry) return 'check-circle'
			if (this.report.isLocked) return 'lock'
			return ''
		},
		template() {
			return this.report && this.report.activeTemplate
		},
	},
	watch: {
		id() {
			this.loadReport()
		},
	},
	async beforeRouteEnter(to, from, next) {
		try {
			nextReport = await getReportDetail(to.params.id)
			// Cancel navigation if images are not ready or error
			if (!nextReport) return next(from.name ? false : BackRouteName)
			next()
		} catch (err) {
			next(from.name ? false : BackRouteName)
		}
	},
	async beforeRouteUpdate(to, from, next) {
		const isLoadingAnotherReport = to.params.id !== from.params.id
		if (!isLoadingAnotherReport) return next()
		this.isPageLoading = true
		await this.saveReport()
		nextReport = await getReportDetail(to.params.id)
		this.isPageLoading = false
		// Cancel navigation if images are not ready or error
		if (!nextReport) return next(from.name ? false : BackRouteName)
		next()
	},
	async beforeRouteLeave(to, from, next) {
		if (this.forceNavigation || !this.claims.isConsultantUser) return next()
		await this.saveReport()
		next()
	},
	async created() {
		this.$store.dispatch('getOnBehalfOfList')
		eventBus.on(REFRESH_REPORT, this.refreshReport)
		eventBus.on(IMAGE_CHANGE, this.refreshReport)
		eventBus.on(REPORT_IMAGE_COMMENT, this.onCommentUpdated)
		this.dbounce = new Dbounce(3000, this.saveReport.bind(this))
		this.loadReport()
		if (this.viewOnOpen) this.openViewer()
	},
	beforeDestroy() {
		eventBus.off(REFRESH_REPORT, this.refreshReport)
		eventBus.off(IMAGE_CHANGE, this.refreshReport)
		eventBus.off(REPORT_IMAGE_COMMENT, this.onCommentUpdated)
		this.dbounce.clear()
	},
	methods: {
		...mapActions(['openStudy']),
		redirectToList() {
			this.forceNavigation = true
			this.$router.push({ name: BackRouteName })
		},
		// Listen for events from other windows spawned from the main Omni window.
		onCommentUpdated(reportId) {
			if (reportId !== this.report.reportId) return
			this.refreshReport()
		},
		async refreshReport() {
			await this.saveReport()
			const report = await getReportDetail(this.id)
			if (!report) return this.redirectToList()
			this.report.refresh(report)
		},
		async loadReport() {
			if (this.$el) this.$el.querySelector('.list-scroll').scrollTo(0, 0)

			this.isPageLoading = true
			this.report = nextReport || (await getReportDetail(this.id))
			nextReport = null
			this.isPageLoading = false
			if (!this.report) return

			if (this.template.root.readOnly) {
				highlightKeywords(this.keywords, '#rootReportLayout')
			}
			this.template.onChangeCB = this.dbounce.set.bind(this.dbounce)
			this.template.pendingChanges = false
		},
		async deleteReport() {
			let result = await showConfirm('Are you sure you want to delete this report?')
			if (!result) return
			reportService.deleteStandardReport(this.id)
			this.$store.dispatch('addNotification', {
				notificationType: 'success',
				message: 'Report deleted!',
			})
			this.redirectToList()
		},
		async saveReport(complete = false) {
			if (!this.template) return
			if (!complete && (this.isReadOnly || !this.template.pendingChanges)) return

			try {
				this.isSaving = true
				let data = this.report.getStandardReportData(complete)
				await reportService.saveStandardReport(data)
				this.template.pendingChanges = false

				if (complete) {
					this.$store.dispatch('addNotification', {
						notificationType: 'success',
						message: 'Report completed!',
					})
					eventBus.broadcast(REPORT_COMPLETED, this.report.reportId)
					this.redirectToList()
				}
			} finally {
				this.isSaving = false
			}
		},
		async openEmailReportDlg() {
			this.isActionsOpen = false
			let report: ReportDetail = this.report
			let studies: IStudy[] = await api.viewer.getStudy({ ids: report.studyIds }, false).then(r => r.studies)

			let images: EmailItem[] = []
			studies.forEach(study => {
				study.imageData.series.forEach(s => {
					images.push(new EmailItem(s, s.images[0], !s.isFakeSeries))
				})
			})

			images = images.filter(i => report.images.some(x => x.id === i.imageId))
			openEmailStudyDlg(studies, images, [report.consultantReportId]).catch(e => {})
		},
		openViewer() {
			this.openStudy({
				clinicCode: this.claims.activeClinicCode,
				reportId: this.report.reportId,
				modality: this.report.study.modality,
			})
		},
		downloadReport(imageType) {
			this.$api.file.downloadReport({
				downloadType: imageType === 'download-jpeg' ? 0 : 1,
				clinicCode: this.claims.activeClinicCode,
				id: this.id,
			})
		},
		openReportPdf() {
			const { reportId, consultantReportId, isComplete } = this.report
			openReportPdf(reportId, consultantReportId, isComplete)
		},
	},
}
async function getReportDetail(id) {
	try {
		const detail = await reportService.getStandardReport(id)
		return detail
	} catch (err) {
		showAlert(err.message)
	}
}
